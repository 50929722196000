import {
  AxesHelper,
  DirectionalLight,
  Fog,
  HemisphereLight,
  MathUtils,
  Object3D,
  Scene,
  Vector3,
} from "three";
import EEventBus from "../helpers/EEventBus";
import { EventBus } from "../helpers/EventBus";
import DvSkyDome, { skyColors } from "./DvSkyDome";

import { degToRad } from "three/src/math/MathUtils";
import SunCalcHelper from "../helpers/SunCalcHelper";
import DvSunBilllboard from "./DvSunBillboard";

import projectData from "../scenes/ProjectData.json";

//TODO: Load from config
const SHADOWMAP_SIZE = 2048;

// TODO: all sunCalcHelper instances only depends on projectData.
// Can a singleton replace all of them?
const sunCalcHelperInit = new SunCalcHelper(
  projectData.geo.latitude,
  projectData.geo.longitude
);

export const DEFAULT_TARGETS = {
  yearTarget: sunCalcHelperInit.getProgressOfTheYear(),
  hourTarget: sunCalcHelperInit.getProgressOfTheHour(),
};

interface DvLightManagerOptions {
  shadowBoxSize: number;
  shadowBias: number;
}

export default class DvLightManager {
  private scene: Scene;

  private hemiLight!: HemisphereLight;
  private sunLight!: DirectionalLight;
  private skyDome: DvSkyDome;
  private sunBillboard!: DvSunBilllboard;

  private options?: DvLightManagerOptions;

  private sunCalcHelper: SunCalcHelper;

  private hourTarget: number = 0;
  private yearTarget: number = 0;

  constructor(scene: Scene, options?: DvLightManagerOptions) {
    this.scene = scene;

    this.options = options;

    const { latitude, longitude } = projectData.geo;
    this.sunCalcHelper = new SunCalcHelper(latitude, longitude);

    this.setupHemisphere();
    this.setupSun();

    this.skyDome = new DvSkyDome(this.scene);

    EventBus.getInstance().register(EEventBus.SET_SUN_TARGET, (data: any) => {
      this.setSunTarget(data.hourTarget, data.yearTarget);
    });

    this.setSunTarget(DEFAULT_TARGETS.hourTarget, DEFAULT_TARGETS.yearTarget);
  }

  setupHemisphere() {
    //TODO: sync colors with SkyDome
    this.hemiLight = new HemisphereLight(0xffffff, 0x0077ff, 1.1);
    this.hemiLight.position.set(0, 10, 0);
    this.scene.add(this.hemiLight);
  }

  setupSun() {
    this.sunLight = new DirectionalLight(0xfff4d6, 1.2);
    this.sunLight.position.set(0, 90, 0);

    this.sunBillboard = new DvSunBilllboard(this.scene);

    const targetObject = new Object3D();
    targetObject.position.set(0, 0, 0);

    this.sunLight.target = targetObject;
    this.sunLight.castShadow = true;
    this.sunLight.shadow.camera.near = 0.1;
    this.sunLight.shadow.camera.far = 3000;

    const sbsize = this.options?.shadowBoxSize || 500;

    this.sunLight.shadow.camera.left = -sbsize;
    this.sunLight.shadow.camera.right = sbsize;
    this.sunLight.shadow.camera.top = sbsize;
    this.sunLight.shadow.camera.bottom = -sbsize;

    this.sunLight.shadow.bias = this.options?.shadowBias || -0.001;
    this.sunLight.shadow.mapSize.width = SHADOWMAP_SIZE;
    this.sunLight.shadow.mapSize.height = SHADOWMAP_SIZE;

    this.scene.add(this.sunLight);
    this.scene.add(this.sunLight.target);

    //DEBUG
    // const dirLightHelper = new DirectionalLightHelper(this.sunLight, 10);
    // this.scene.add(dirLightHelper);
  }

  //in range -1 to 1 (sunrise to sunset)
  setSunTarget(hourTarget: number, yearTarget: number) {
    this.hourTarget = hourTarget || this.hourTarget;
    this.yearTarget = yearTarget || this.yearTarget;

    const sunData = this.sunCalcHelper.getSunData(
      this.yearTarget,
      this.hourTarget
    );

    //convert sunData Spherical Coordinates to Cartesian
    const cartesianPos = new Vector3(
      Math.sin(sunData.zenith) * Math.cos(sunData.azimuth + degToRad(90)), //cartesian x
      Math.cos(sunData.zenith), //cartesian z
      Math.sin(sunData.zenith) * Math.sin(sunData.azimuth + degToRad(90)) //cartesian y
    );

    //debug lines
    // const points = [new Vector3(), cartesianPos];
    // this.scene.add(
    //   new Line(
    //     new BufferGeometry().setFromPoints(points),
    //     new LineBasicMaterial({ color: 0x0000ff })
    //   )
    // );
    // const axesHelper = new AxesHelper(20);
    // axesHelper.position.set(20, 10, 0);
    // this.scene.add(axesHelper);
    //debug lines end

    // set sunLight position in a 100-radius arc
    this.sunLight.position.set(
      100 * cartesianPos.x,
      100 * cartesianPos.y,
      100 * cartesianPos.z
    );
    // set sunBillboard position in a 3000-radius arc (skyDome has 4000, so keep inside)
    this.sunBillboard.setPosition(
      cartesianPos.x * 3000,
      cartesianPos.y * 3000,
      cartesianPos.z * 3000
    );

    this.skyDome.setColorTarget(this.hourTarget);

    const interpolatedValue = this.skyDome.interpolateValue(
      this.hourTarget * 2 - 1
    );
    const interpolatedColor = skyColors.noonBottom
      .clone()
      .lerp(skyColors.sunsetSunriseBottom, interpolatedValue);

    this.scene.fog = new Fog(interpolatedColor, 100, 1000);
    this.sunLight.color = interpolatedColor;
  }
}
