import {
  EquirectangularReflectionMapping,
  Mesh,
  MeshStandardMaterial,
  sRGBEncoding,
  TextureLoader,
} from "three";

const textureLoader = new TextureLoader();

// Textures
const envTexture = textureLoader.load("/assets/sky.jpg");
envTexture.mapping = EquirectangularReflectionMapping;
envTexture.encoding = sRGBEncoding;

type MaterialsLib = {
  [key: string]: MeshStandardMaterial;
};

const materials: MaterialsLib = {
  Generic_Glass_1: new MeshStandardMaterial({
    roughness: 0.2,
    metalness: 1,
    envMap: envTexture,
    envMapIntensity: 0.5,
    color: 0xffffff,
    name: "Generic_Glass_1_Override",
  }),
};

export default function overrideMaterial(mesh: Mesh) {
  mesh.material = materials[(mesh.material as MeshStandardMaterial).name];
  mesh.material.needsUpdate = true;
}
