import { createContext, ReactNode } from "react";

export interface ApDrawerContextProps {
  content: ReactNode;
  toggle(content: ReactNode): void;
  open(content: ReactNode): void;
  close(): void;
}

export const ApDrawerContext = createContext<ApDrawerContextProps>({
  content: null,
  toggle: (content: ReactNode) => { },
  open: (content: ReactNode) => { },
  close: () => { },
});
