import {
  Color,
  Fog,
  Mesh,
  MeshStandardMaterial,
  PCFSoftShadowMap,
  Scene,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
} from "three";

import Stats from "three/examples/jsm/libs/stats.module.js";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { degToRad } from "three/src/math/MathUtils";
import DvEdificeCamera from "../dynve/DvEdificeCamera";
import DvLightManager from "../dynve/DvLightManager";
import DvNeighborBuilding from "../dynve/DvNeighborBuilding";
import ConfigManager, { ConfigOptions } from "../helpers/ConfigManager";
import overrideMaterial from "../helpers/MaterialOverrider";
import DvSceneBase from "./DvSceneBase";

//TODO: implement loader and GNodesManager
import gnodes from "./GNodes.json";

import projectData from "./ProjectData.json";

export default class DvEdifice extends DvSceneBase {
  private scene: Scene;
  private renderer: WebGLRenderer;
  private camera: DvEdificeCamera;

  private model?: GLTF;
  private neighborhood = new Array<DvNeighborBuilding>();

  private stats?: Stats;

  private gnodeTemplates = new Array<any>();

  constructor(canvas: HTMLCanvasElement) {
    super();

    this.scene = new Scene();
    this.scene.background = new Color().setHSL(0.6, 0, 1);
    this.scene.fog = new Fog(this.scene.background, 100, 1000);

    this.renderer = new WebGLRenderer({
      canvas,
      antialias: true,
    });
    this.renderer.setPixelRatio(
      ConfigManager.getNumber(
        ConfigOptions.PIXEL_RATIO,
        window.devicePixelRatio
      )
    );
    this.renderer.shadowMap.type = PCFSoftShadowMap; //THREE.BasicShadowMap | THREE.PCFShadowMap |  THREE.VSMShadowMap | THREE.PCFSoftShadowMap
    this.renderer.shadowMap.enabled = true;
    this.renderer.outputEncoding = sRGBEncoding;

    //set initial renderer size and watch window resize
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    window.addEventListener("resize", () => {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
    });

    this.camera = new DvEdificeCamera(this.renderer);

    this.loadModel(
      "https://assets-ooglon.nyc3.cdn.digitaloceanspaces.com/faax%2F01-edsaoluiz%2Fv2%2Fexternal.glb"
    );

    const lm = new DvLightManager(this.scene);

    //temp stats
    if (ConfigManager.getBoolean(ConfigOptions.SHOW_STATS)) {
      this.stats = Stats();
      document.body.appendChild(this.stats.dom);
    }

    this.update();

    console.log("DynVE Initialized!");
  }

  public dispose() {
    //forcing context loss seems to work better to avoid slowdown after url switching
    this.renderer.forceContextLoss();

    // this.renderer.dispose();
  }

  loadModel(filename: string) {
    const loader = new GLTFLoader();

    loader.load(
      filename,
      (gltf) => {
        // console.log(gltf);
        this.model = gltf;

        gltf.scene.traverse((child) => {
          // console.log(child);
          // if (child.isMesh) {
          if (child.type === "Mesh") {
            if (child.name.startsWith("Neighborhood")) {
              this.neighborhood.push(
                new DvNeighborBuilding(
                  child as Mesh,
                  child.name
                    .replace("Neighborhood-", "")
                    .replaceAll("-", " ")
                    .replaceAll("\\n", "\n")
                )
              );
            } else {
              child.castShadow = true;
              child.receiveShadow = true;
            }

            //override materials
            const meshMat = (child as Mesh).material as MeshStandardMaterial;
            if (meshMat.name === "Generic_Glass_1") {
              overrideMaterial(child as Mesh);
            }
          }

          //register Meshs for GNodes
          if (child.name.startsWith("Tree")) {
            // console.log("template", child.name);
            this.gnodeTemplates.push(child);
          }
        });

        this.setupNeighborhood();

        this.scene.add(gltf.scene);

        this.instantiateGNodes();

        // apply camera initial position to match north
        this.setCameraInitialPos();

        // dispatch finish event
        this.onCompleteLoading();
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );
  }

  instantiateGNodes() {
    gnodes.forEach((gnode) => {
      const ginst = this.gnodeTemplates[0].clone(true);

      ginst.traverse((child: Mesh) => {
        child.castShadow = true;
        child.receiveShadow = true;
      });

      ginst.position.set(gnode.pos.x, gnode.pos.y, gnode.pos.z);
      ginst.rotation.set(gnode.rot.x, gnode.rot.y, gnode.rot.z);

      this.scene.add(ginst);
    });
  }

  setCameraInitialPos() {
    const target = this.camera.getTarget();
    this.camera.position.set(target.x, target.y + 40, target.z - 60);
    this.camera.lookAt(target.x, target.y, target.z);
  }

  setupNeighborhood() {
    this.neighborhood.forEach((dvn) => {
      dvn.setup(this.scene);
    });
  }

  // ******************* RENDER LOOP ******************* //
  update() {
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.update.bind(this));

    this.stats?.update();
  }
}
