import {
  BackSide,
  Color,
  Mesh,
  Scene,
  ShaderMaterial,
  SphereGeometry,
} from "three";

const vertexShader = `
  varying vec3 vWorldPosition;

  void main() {

    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
    vWorldPosition = worldPosition.xyz;

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

  }
`;

const fragmentShader = `
  uniform vec3 topColor;
	uniform vec3 bottomColor;
  uniform float offset;
  uniform float exponent;

  varying vec3 vWorldPosition;

  void main() {

    float h = normalize( vWorldPosition + offset ).y;
    gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max( h , 0.0), exponent ), 0.0 ) ), 1.0 );

  }
`;

//Noon: top:0x0077ff, bot:0xffffff
//Sunset/Sunrise: top:0x7d7d7c, bot:0xff8c1a

export const skyColors = {
  noonTop: new Color(0x0077ff),
  noonBottom: new Color(0xffffff),
  sunsetSunriseTop: new Color(0x666364),
  sunsetSunriseBottom: new Color(0xffad62),
};

const uniforms = {
  topColor: { value: skyColors.noonTop.clone() },
  bottomColor: { value: skyColors.noonBottom.clone() },
  offset: { value: 33 },
  exponent: { value: 0.6 },
};

export default class DvSkyDome {
  private skyMat: ShaderMaterial;

  constructor(scene: Scene) {
    const skyGeo = new SphereGeometry(4000, 32, 15);

    this.skyMat = new ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: BackSide,
    });

    const sky = new Mesh(skyGeo, this.skyMat);
    scene.add(sky);
    this.setColorTarget(0);
  }

  //in range -1 to 1 (sunrise to sunset)
  public setColorTarget(target: number) {
    const interpolation = this.interpolateValue(target * 2 - 1);

    const interpolatedBottom = skyColors.noonBottom
      .clone()
      .lerp(skyColors.sunsetSunriseBottom, interpolation);

    const interpolatedTop = skyColors.noonTop
      .clone()
      .lerp(skyColors.sunsetSunriseTop, interpolation);

    this.skyMat.uniforms["bottomColor"].value.set(interpolatedBottom);
    this.skyMat.uniforms["topColor"].value.set(interpolatedTop);
  }

  public interpolateValue(target: number): number {
    return 1 - Math.cos(Math.pow(target, 2) * (Math.PI / 2));
  }
}
