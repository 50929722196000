import { ScrollArea } from "@mantine/core";
import { useHotkeys, useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
  AiOutlineMinus,
  AiOutlinePlus,
} from "react-icons/ai";
import { MAX_FLOOR, MIN_FLOOR } from "..";
import getDronePhoto from "../../../../../data/drone-photos/images";
import { useDoubleTap } from "../../../../../hooks/useDoubleTap";

import styles from "./styles.module.css";

interface DroneViewFullscreenProps {
  visible: boolean;
  onClose: Function;
  floor: number;
  plan: number;
  controls: {
    handleUpstairs: React.MouseEventHandler<HTMLButtonElement>;
    handleDownstairs: React.MouseEventHandler<HTMLButtonElement>;
    handleRightPlan: React.MouseEventHandler<HTMLButtonElement>;
    handleLeftPlan: React.MouseEventHandler<HTMLButtonElement>;
    currentApNumber: Function;
  };
}

export default function DroneViewFullscreen({
  visible,
  onClose,
  floor,
  plan,
  controls,
}: DroneViewFullscreenProps) {
  const isMobile = useMediaQuery("only screen and (max-width: 600px)");
  const [zoom, setZoom] = useState(false);
  const bind = useDoubleTap(() => {
    toggleZoom();
  });
  useHotkeys([["Escape", () => onClose()]]);

  if (!visible) return <></>;

  const src = getDronePhoto(floor, plan);
  const title = "Vista da Sacada: Apartamento " + controls.currentApNumber();

  const toggleZoom = () => {
    setZoom((value) => !value);
  };

  const getZoomStyle = () => {
    if (isMobile) {
      return zoom ? {} : { width: "100vw", maxHeight: "calc( 100vh - 96px )" };
    }
    return zoom ? { width: "100vw" } : { maxHeight: "calc( 100vh - 96px )" };
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.darkbar} ${styles.header}`}>
        <span>{title}</span>
        <div>
          <button onClick={toggleZoom}>
            {zoom ? (
              <AiOutlineFullscreenExit size={22} />
            ) : (
              <AiOutlineFullscreen size={22} />
            )}
          </button>

          <button onClick={() => onClose()}>
            <AiOutlineClose size={22} />
          </button>
        </div>
      </div>

      <ScrollArea className={styles.scrollable}>
        <img {...bind} src={src} alt={title} style={getZoomStyle()} />
      </ScrollArea>

      <div className={styles.darkbar}>
        <button
          onClick={controls.handleDownstairs}
          disabled={floor == MIN_FLOOR}
        >
          <AiOutlineMinus size={22} />
        </button>

        <button onClick={controls.handleLeftPlan}>
          <AiOutlineArrowLeft size={22} />
        </button>

        <span>AP {controls.currentApNumber()}</span>

        <button onClick={controls.handleRightPlan}>
          <AiOutlineArrowRight size={22} />
        </button>

        <button onClick={controls.handleUpstairs} disabled={floor == MAX_FLOOR}>
          <AiOutlinePlus size={22} />
        </button>
      </div>
    </div>
  );
}
