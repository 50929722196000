import { Text } from "@mantine/core";
import { ReactNode } from "react";

import styles from "../styles.module.css";

export interface ApPanelTextProps {
  children: ReactNode;
}

const childrenToHtmlString = (children: ReactNode) => {
  if (Array.isArray(children)) {
    return children.join('');
  }
  return children?.toString() || '';
}

export default function ApPanelTextCard({ children }: ApPanelTextProps) {
 
  return (
    <Text 
      size="sm"
      mt="sm"
      className={styles.text}
    >
      
      <div dangerouslySetInnerHTML={{ __html: childrenToHtmlString(children) }} />
    </Text>
  );
}
