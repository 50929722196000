import {
  AdditiveBlending,
  BufferGeometry,
  Float32BufferAttribute,
  Points,
  PointsMaterial,
  Scene,
  TextureLoader,
} from "three";

export default class DvSunBilllboard {
  private scene: Scene;
  private sunPoint: Points;

  constructor(scene: Scene) {
    this.scene = scene;

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new Float32BufferAttribute([0, 0, 0], 3));

    const material = new PointsMaterial({
      size: 700,
      map: new TextureLoader().load("/assets/sun.png"),
      transparent: true,
      blending: AdditiveBlending,
    });

    this.sunPoint = new Points(geometry, material);

    this.scene.add(this.sunPoint);
  }

  public setPosition(x: number, y: number, z: number) {
    this.sunPoint.position.set(x, y, z);
  }
}
