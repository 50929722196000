import {
  MathUtils,
  PerspectiveCamera,
  Quaternion,
  Vector2,
  Vector3,
  WebGLRenderer,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { radToDeg } from "three/src/math/MathUtils";
import EEventBus from "../helpers/EEventBus";
import { EventBus } from "../helpers/EventBus";
import ConfigManager, { ConfigOptions } from "../helpers/ConfigManager";

interface DvEdificeOptions {
  minDistance: number;
  maxDistance: number;
}

export default class DvEdificeCamera extends PerspectiveCamera {
  private renderer: WebGLRenderer;

  private controls!: OrbitControls;
  private options?: DvEdificeOptions;

  constructor(renderer: WebGLRenderer, options?: DvEdificeOptions) {
    super(75, window.innerWidth / window.innerHeight, 0.1, 5000);

    this.options = options;

    this.renderer = renderer;
    this.setupControls();

    EventBus.getInstance().register(
      EEventBus.SET_CAMERA_HEIGHT,
      (target: number) => {
        this.setCameraHeight(target);
      }
    );
  }

  /**
   * Calculates angle between this camera and mesh origin (0,0,0). Used to project 2D reference for north (Z+ axis).
   * Adapted from angleBetweenTwoPointsWithFixedPoint from https://stackoverflow.com/questions/26076656/calculating-angle-between-two-points-java
   * @returns angle in deg
   */
  getRotationAngleToOrigin() {
    //correct origin with camera target offset
    const point = [
      this.position.x - this.controls.target.x,
      this.controls.target.z - this.position.z,
    ];
    const angle1 = Math.atan2(point[1], point[0]);
    const angle2 = Math.atan2(1, 0);
    const degResult = radToDeg(angle1 - angle2);
    if (degResult < 0) return degResult + 360;
    return degResult;
  }

  setupControls() {
    this.controls = new OrbitControls(this, this.renderer.domElement);
    this.controls.minDistance = this.options?.minDistance || 40;
    this.controls.maxDistance = this.options?.maxDistance || 200;
    this.controls.maxPolarAngle = MathUtils.degToRad(80);
    this.controls.enablePan = ConfigManager.getBoolean(
      ConfigOptions.CAMERA_ENABLE_PAN
    );
    this.controls.target.set(0, 0, 0);
    this.controls.update();

    this.controls.addEventListener("change", (event) => {
      EventBus.getInstance().dispatch<number>(
        EEventBus.UPDATED_CAMERA_ROTATION_ANGLE,
        this.getRotationAngleToOrigin()
      );
    });
  }

  setCameraHeight(target: number) {
    this.controls.target.set(0, target * 50, 0);
    this.controls.update();
  }

  setTarget(x: number, y: number, z: number) {
    this.controls.target.set(x, y, z);
  }

  getTarget() {
    return this.controls.target;
  }
}
