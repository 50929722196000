import { useEffect, useState } from "react";

import styles from "./styles.module.css";

import EEventBus from "../../webgl/helpers/EEventBus";
import { EventBus } from "../../webgl/helpers/EventBus";
import loadingLogo from "./loadingLogo.png";
import preloaderGif from "./preloader.gif";

/**
 * This React component is triggered by EventBus
 */
export default function LoadingScreen() {
  const [opacity, setOpacity] = useState(1);
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    EventBus.getInstance().register(
      EEventBus.SET_LOADING_SCREEN_VISIBLE,
      (visible: boolean) => {
        setOpacity(visible ? 1 : 0);

        //disable preloader gif on "enter loading screen" transition
        if (visible) setShowPreloader(false);
      }
    );

    // show prelaoder gif if loading takes longer than 2s
    setTimeout(() => {
      if (opacity == 1) setShowPreloader(true);
    }, 2000);
  }, []);

  return (
    <div className={styles.container} style={{ opacity }}>
      <img src={loadingLogo} alt="logo" />
      {showPreloader && (
        <img
          src={preloaderGif}
          alt="preloader gif"
          className={styles.preloader}
        />
      )}
    </div>
  );
}
