import { ReactElement, ReactNode } from "react";

export const isOverridingComponent = (
  oldComponent: ReactNode,
  newComponent: ReactNode
): boolean => {
  const oldKey = (oldComponent as ReactElement)?.key;
  const newKey = (newComponent as ReactElement)?.key;

  return oldKey != newKey || (oldKey == null && newKey == null);
};
