import {
  BoxGeometry,
  BufferGeometry,
  DoubleSide,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Scene,
  Vector3,
} from "three";
import EEventBus from "../helpers/EEventBus";
import { EventBus } from "../helpers/EventBus";
import SpriteText from "../lib/three-spritetext";

export default class DvMeasure {
  private scene: Scene;
  private originalMesh: Mesh;
  private label: SpriteText;

  constructor(scene: Scene, mesh: Mesh) {
    this.scene = scene;
    this.originalMesh = mesh;

    const txt = mesh.name.split("_")[2];

    this.label = new SpriteText(txt);
    this.label.fontSize = 24;
    this.label.fontWeight = "bold";
    this.label.strokeColor = "white";
    this.label.strokeWidth = 1;
    this.label.color = "black";
    this.label.renderOrder = 0;
    this.label.position.set(0, 0.3, 0);
    this.label.scale.multiplyScalar(0.03);

    // add label as child of mesh
    mesh.add(this.label);

    mesh.visible = false;

    EventBus.getInstance().register(
      EEventBus.TOGGLE_MEASURES,
      () => (mesh.visible = !mesh.visible)
    );
  }

  //TODO:remove setup if not used
  public setup() {
    // this.scene.add(this.label);
    // this.originalMesh.material = measureMaterial;
  }

  public setVisible(visible: boolean) {}
}
