import ApDrawer from "../../components/ApDrawer";
import ApTopMenu, {
  ApTopMenuItems,
  ApTopMenuPosition,
  ApTopMenuShape,
} from "../../components/ApTopMenu";
import FrontContainer from "../../components/FrontContainer";
import Vignette from "../../components/Vignette";
import WebGLWrapper from "../../components/WebGLWrapper";
import { ImStack } from "react-icons/im";
import { useDocumentTitle } from "@mantine/hooks";
import JoyrideHome from "../../components/Joyrides/JoyrideHome";
import WatermarkOOGLON from "../../components/WatermarkOOGLON";
import BuildingName from "../../components/BuildingName";
import { FiHelpCircle } from "react-icons/fi";

const menuShape: ApTopMenuShape = [
  {
    block: 1,
    position: ApTopMenuPosition.LEFT,
    items: [
      // { id: ApTopMenuItems.MAIN_MENU },
      { id: ApTopMenuItems.CONFIG_BUTTON },
      { id: ApTopMenuItems.INFO_BUTTON, args: { text: "Info Text" } },
      {
        id: ApTopMenuItems.GENERIC_BUTTON,
        args: {
          innerComponent: <FiHelpCircle size={20} color="dimgray" />,
          onClick: () => {
            localStorage.removeItem("joyrideHomeShown");
            window.location.reload();
          },
          id: "RepeatJoyrideButton",
        },
      },
    ],
  },
  {
    block: 2,
    position: ApTopMenuPosition.LEFT,
    items: [{ id: ApTopMenuItems.PARTNERS_BUTTON }],
  },
  {
    block: 3,
    position: ApTopMenuPosition.LEFT,
    items: [{ id: ApTopMenuItems.CAMERA_HEIGHT_CONTROLLER }],
  },
  {
    block: 4,
    position: ApTopMenuPosition.RIGHT,
    items: [
      {
        id: ApTopMenuItems.GENERIC_BUTTON,
        args: {
          innerComponent: <ImStack size={22} color="dimgray" />,
          onClick: () => (window.location.href = "/ap"),
          id: "LinkToApPage",
        },
      },
    ],
  },
  {
    block: 5,
    position: ApTopMenuPosition.RIGHT,
    items: [
      { id: ApTopMenuItems.MBUILDING_DETAILS },
      { id: ApTopMenuItems.MSUN_CONTROLLER },
      { id: ApTopMenuItems.TOGGLE_NEIGHBORHOOD },
      { id: ApTopMenuItems.BROKERS_CONTACT_BUTTON },
      { id: ApTopMenuItems.SHARE_BUTTON },
    ],
  },
  {
    block: 6,
    position: ApTopMenuPosition.RIGHT,
    items: [{ id: ApTopMenuItems.NORTH_INDICATOR }],
  },
];

export default function PageHome() {
  useDocumentTitle("Edifício São Luiz");

  return (
    <ApDrawer>
      <WebGLWrapper dvSceneName="DvEdifice" />
      <Vignette />
      <WatermarkOOGLON />
      <BuildingName />
      <FrontContainer>
        <ApTopMenu menuShape={menuShape} />
      </FrontContainer>
      <JoyrideHome />
    </ApDrawer>
  );
}
