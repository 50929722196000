import React, { ReactNode, useState, useEffect, ReactElement } from "react";

import Drawer from "react-drag-drawer";
import { CloseButton, UnstyledButton } from "@mantine/core";

import { ApDrawerContext, ApDrawerContextProps } from "./ApDrawerContext";
import { isOverridingComponent } from "./ApDrawerHelpers";

import styles from "./styles.module.css";
import { DecrementWrapStencilOp } from "three";
import { FaRegWindowMinimize } from "react-icons/fa";
import { MdHorizontalRule, MdOutlineHorizontalRule } from "react-icons/md";

export interface ApDrawerProps {
  children: ReactNode;
}

export default function AppleDrawer({ children }: ApDrawerProps) {
  const [content, setContent] = useState<ReactNode>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (modalRef && containerRef) {
      if (modalRef.offsetHeight > window.innerHeight) {
        modalRef.style.removeProperty("bottom");

        modalRef.style["top"] = "30px";
        containerRef.style["position"] = "fixed";
      } else {
        modalRef.style.removeProperty("top");

        modalRef.style["bottom"] = "0";
        containerRef.style["position"] = "initial";
      }
    }
  }, [modalRef, content]);

  const drawerFuncs = {
    open: (content: ReactNode) => {
      setContent(content);
      setOpen(true);

      // Register all open panel events at Google Analytics
      const eventName = (content as ReactElement)?.key || "OpenPanel";
      window.gtag("event", eventName.toString());
    },
    close: () => {
      setContent(null);
      setOpen(false);
    },
    toggle: () => {
      setOpen(!open);
      setContent(null);
    },
  };

  const apDrawer: ApDrawerContextProps = {
    open: (content: ReactNode) => {
      drawerFuncs.open(content);
    },
    toggle: (newContent: ReactNode) => {
      if (isOverridingComponent(content, newContent)) {
        drawerFuncs.open(newContent);
      } else {
        drawerFuncs.close();
      }
    },
    close: () => {
      drawerFuncs.close();
    },
    content,
  };

  return (
    <ApDrawerContext.Provider value={apDrawer}>
      <Drawer
        open={open}
        direction="bottom"
        onRequestClose={drawerFuncs.toggle}
        containerElementClass={styles.modalContainer}
        modalElementClass={`${styles.modalElement} global-shadow`}
        getModalRef={(ref: HTMLDivElement) => setModalRef(ref)}
        getContainerRef={(ref: HTMLDivElement) => setContainerRef(ref)}
      >
        <UnstyledButton
          className={styles.closeContainer}
          onClick={() => drawerFuncs.close()}
        >
          <MdHorizontalRule size={26} />
        </UnstyledButton>

        <div className={styles.content}>{content}</div>
      </Drawer>

      {children}
    </ApDrawerContext.Provider>
  );
}
