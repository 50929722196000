import ApPanel from "../.";
import LightboxGallery, { CustomImage } from "../../Gallery";

export interface ApPanelCardsProps {
  title?: string;
  cards: Array<ApPanelCardsDataProps>;
  images?: Array<CustomImage>;
}

export interface ApPanelCardsDataProps {
  key: string;
  title: string;
  text?: string;
  itens?: Array<ApPanelCardsDataItensProps>;
}

export interface ApPanelCardsDataItensProps {
  name: string;
  icon: string;
}

export default function ApPanelCards({ title, images, cards }: ApPanelCardsProps) {
  return (
    <ApPanel title={title}>

      {cards.map((el, index) => (
        <ApPanel.Card key={index} title={el.title}>
          {el.text && <ApPanel.Text> {el.text} </ApPanel.Text>}
          {el.itens && <ApPanel.List itens={ el.itens } />}
          {el.key === 'gallery' && images && <LightboxGallery images={images} /> }
        </ApPanel.Card>
      ))}

    </ApPanel>
  );
}
