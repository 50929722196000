import {
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  Scene,
  Vector3,
} from "three";
import SpriteText from "../lib/three-spritetext";

import EEventBus from "../helpers/EEventBus";
import { EventBus } from "../helpers/EventBus";

export default class DvNeighborBuilding {
  private originalMesh: Mesh;
  private ghostMesh: Mesh;
  private label: SpriteText;
  private pin: Mesh;

  //TODO: make optional params in ctor
  private labelStartSize = 0.2;
  private labelScaleFactor = 1.1;
  private labelMaxDistance = 500;

  private labelHeight = 30;

  constructor(mesh: Mesh, labelText: string) {
    this.originalMesh = mesh;

    mesh.material = new MeshStandardMaterial({
      color: 0xffffff,
      roughness: 1,
      metalness: 0,
      opacity: 0.4,
      transparent: true,
      // side: DoubleSide,
    });

    mesh.traverse((onode) => {
      let node = onode as Mesh;
      if (node.material) {
        node.renderOrder = 0;
        (node.material as MeshStandardMaterial).colorWrite = false;
      }
    });

    this.ghostMesh = mesh.clone();
    this.ghostMesh.traverse((onode) => {
      let node = onode as Mesh;
      if (node.material) {
        node.renderOrder = 1;
        node.material = (node.material as MeshStandardMaterial).clone();
        node.material.colorWrite = true;
      }
    });

    this.label = new SpriteText(labelText);
    this.label.fontSize = 24;
    this.label.fontWeight = "bold";
    this.label.backgroundColor = "white";
    this.label.borderRadius = 4;
    this.label.padding = 2;
    this.label.color = "rgb(50,50,50)";
    this.label.renderOrder = -1;
    this.label.position.set(mesh.position.x, this.labelHeight, mesh.position.z);
    //label distance-based scale
    let dist = mesh.position.distanceTo(new Vector3());
    dist /= this.labelMaxDistance;
    dist = this.labelStartSize + dist * this.labelScaleFactor;
    this.label.scale.multiplyScalar(dist);

    //pin
    const geometry = new BoxGeometry(0.3, this.labelHeight, 0.3);
    const material = new MeshBasicMaterial({ color: "white" });
    this.pin = new Mesh(geometry, material);
    const { x, z } = this.originalMesh.position;
    this.pin.position.set(x, this.labelHeight / 2, z);
  }

  public setup(scene: Scene) {
    scene.add(this.ghostMesh);

    if (!this.label.text.includes("NOLABEL")) {
      scene.add(this.label);
      scene.add(this.pin);
    }

    EventBus.getInstance().register(EEventBus.TOGGLE_NEIGHBORHOOD, () => {
      this.setVisible(!this.originalMesh.visible);
    });
  }

  public setVisible(visible: boolean) {
    this.originalMesh.visible = visible;
    this.ghostMesh.visible = visible;
    this.label.visible = visible;
    this.pin.visible = visible;
  }
}
