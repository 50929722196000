import { useState, useEffect, useRef } from "react";

import EEventBus from "../webgl/helpers/EEventBus";
import { EventBus } from "../webgl/helpers/EventBus";

function useToggleUI() {
  const [visibled, _setVisibled] = useState(true);

  const visibleRef = useRef(visibled);

  const setVisibled = (bool: boolean) => {
    visibleRef.current = bool;
    _setVisibled(bool);
  };

  useEffect(() => {
    const event = EventBus.getInstance().register(EEventBus.TOGGLE_UI, () => {
      setVisibled(!visibleRef.current);
    });

    return () => {
      event.unregister();
    };
  }, []);

  return visibled;
}

// use like this:
// const display = useToggleDisplayUI()
// <div style={{ display }}> ... </div>
function useToggleDisplayUI() {
  return useToggleUI() ? "block" : "none";
}

export { useToggleUI, useToggleDisplayUI };
