import { useState } from "react";

import { Gallery, Image } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import styles from "./styles.module.css";

export interface CustomImage extends Image {
  original: string;
}

interface LightboxGalleryProps {
  images: Array<CustomImage>;
}

export default function LightboxGallery({ images }: LightboxGalleryProps) {

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div className={styles.gallery}>
      <Gallery images={images} enableImageSelection={false} onClick={handleClick} />

      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}
