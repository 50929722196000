import Joyride, { Step } from "react-joyride";
import { locale } from "./locale";
import { Title, Text } from "@mantine/core";

const steps: Array<Step> = [
  {
    content: (
      <div>
        <Title order={2}>Seja Bem-vindo ao Edifício São Luiz</Title>

        <Text mt="xs">
          Conheça este empreendimento por meio da sua Maquete Virtual!
        </Text>
        <Text mt="xs">Antes de começar, que tal um tour rápido?</Text>
      </div>
    ),
    placement: "center",
    target: "body",
  },
  {
    target: "#MBuildingDetails",
    content: "Conheça os detalhes e a infraestrutura do empreendimento",
  },
  {
    target: "#MSunController",
    content:
      "Simule em tempo real a iluminação em diferentes horários e épocas do ano",
  },
  {
    target: "#ToggleNeighborhood",
    content: "Alterne a visualização da vizinhança do empreendimento",
  },
  {
    target: "#LinkToApPage",
    content: "Acesse a maquete interativa de cada apartamento",
  },
  {
    target: "#ShareButton",
    content: "Compartilhe esta novidade!",
  },
  {
    content: (
      <div>
        <Text mt="xs">
          Para uma melhor navegação na Maquete Virtual,
          você pode alternar a visualização do menu clicando duas vezes na tela.
        </Text>
      </div>
    ),
    placement: "center",
    target: "body",
  },
];

export default function JoyrideHome() {
  const alreadyShown = localStorage.getItem("joyrideHomeShown");

  if (alreadyShown) return <></>;

  localStorage.setItem("joyrideHomeShown", "true");

  return (
    <Joyride
      steps={steps}
      run={true}
      continuous
      hideCloseButton
      scrollToFirstStep
      showProgress
      showSkipButton
      locale={locale}
      styles={{
        options: {
          primaryColor: "#228be6",
        },
      }}
    />
  );
}
