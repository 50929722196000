enum EEventBus {
  AP_DRAWER_SET_STEP = "AP_DRAWER_SET_STEP", //TODO:rename to SET_... check if still in use
  SET_APARTMENT_PLAN = "SET_APARTMENT_PLAN",
  SET_APARTMENT_FLOOR = "SET_APARTMENT_FLOOR",
  SET_BALCONY_VIEW_POS = "SET_BALCONY_VIEW_POS",
  SET_CAMERA_HEIGHT = "SET_CAMERA_HEIGHT",
  SET_LOADING_SCREEN_VISIBLE = "SET_LOADING_SCREEN_VISIBLE",
  SET_SUN_TARGET = "SET_SUN_TARGET",
  TOGGLE_FURNITURE = "TOGGLE_FURNITURE",
  TOGGLE_MEASURES = "TOGGLE_MEASURES",
  TOGGLE_NEIGHBORHOOD = "TOGGLE_NEIGHBORHOOD",
  UPDATED_CAMERA_ROTATION_ANGLE = "UPDATED_CAMERA_ROTATION_ANGLE",
  TOGGLE_UI = "TOGGLE_UI",
}

export default EEventBus;
