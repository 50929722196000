import React, { useEffect, useRef } from "react";
import DvEdifice from "../../webgl/scenes/DvEdifice";
import DvEdificeInternal from "../../webgl/scenes/DvEdificeInternal";
import LoadingScreen from "../LoadingScreen";
import EEventBus from "../../webgl/helpers/EEventBus";
import { EventBus } from "../../webgl/helpers/EventBus";

import styles from "./styles.module.css";

interface WebGLWrapperProps {
  dvSceneName: string;
}

function WebGLWrapper({ dvSceneName }: WebGLWrapperProps) {
  const reactCanvas = useRef<HTMLCanvasElement | null>(null);

  // const dvScene = useMemo( new DvEdifice(reactCanvas.current) , []);
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    let dvScene: any;

    switch (dvSceneName) {
      case "DvEdifice":
        dvScene = new DvEdifice(canvas);
        break;
      case "DvEdificeInternal":
        dvScene = new DvEdificeInternal(canvas);
        break;
      default:
        console.error(
          "DvScene " + dvSceneName + " not registered in WebGLWrapper!"
        );
    }

    const dispatchHideMenuEvent = () => {
      EventBus.getInstance().dispatch(EEventBus.TOGGLE_UI);
    };

    canvas.addEventListener("dblclick", dispatchHideMenuEvent);

    return () => {
      canvas.removeEventListener("dblclick", dispatchHideMenuEvent);
      dvScene.dispose();
    };
  }, []);

  return (
    <>
      <canvas ref={reactCanvas} className={styles.canvas} />
      <LoadingScreen />
    </>
  );
}

//TODO: check if memo is a good choice (problem dealing with multiple pages with multiple renderers?)
export default React.memo(WebGLWrapper);
// export default BabylonWrapper;
