import Joyride, { Step } from "react-joyride";
import { locale } from "./locale";
import { Title, Text } from "@mantine/core";

const steps: Array<Step> = [
  {
    content: (
      <div>
        <Title order={2}>Edifício São Luiz</Title>

        <Text mt="xs">Conheça os apartamentos deste empreendimento.</Text>
        <Text mt="xs">
          Vamos fazer mais um tour para conhecer os recursos adicionais desta
          área?
        </Text>
      </div>
    ),
    placement: "center",
    target: "body",
  },
  {
    target: "#FloorMiniMap",
    content:
      "Navegue pelos apartamentos e visualize a Vista da Sacada pelo nosso Mini-Mapa",
  },
  {
    target: "#ToggleFurniture",
    content: "Alterne a visualização da mobília do apartamento",
  },
  {
    target: "#ToggleMeasures",
    content: "Visualize as medidas da planta do apartamento",
  },
  {
    target: "#BrokersContactButton",
    content: "Ficou com alguma dúvida? Fale com os nossos corretores parceiros",
  },
  {
    target: "#HomeButton",
    content: "Retorne a visão geral do empreendimento",
  },
];

export default function JoyrideAp() {
  const alreadyShown = localStorage.getItem("joyrideApShown");

  if (alreadyShown) return <></>;

  localStorage.setItem("joyrideApShown", "true");

  return (
    <Joyride
      steps={steps}
      run={true}
      continuous
      hideCloseButton
      scrollToFirstStep
      showProgress
      showSkipButton
      locale={locale}
      styles={{
        options: {
          primaryColor: "#228be6",
        },
      }}
    />
  );
}
