import EEventBus from "../helpers/EEventBus";
import { EventBus } from "../helpers/EventBus";

export default abstract class DvSceneBase {
  onCompleteLoading() {
    EventBus.getInstance().dispatch<boolean>(
      EEventBus.SET_LOADING_SCREEN_VISIBLE,
      false
    );
  }
}
