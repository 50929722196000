const getDir = (plan: number) => {
  switch (plan) {
    case 0:
      return "SW";
      break;
    case 1:
      return "NW";
      break;
    case 2:
      return "SE";
      break;
    case 3:
      return "NE";
  }
};

export default function getDronePhoto(floor: number, plan: number) {
  return (
    "/assets/photos/drone/" +
    getDir(plan) +
    "/" +
    floor.toLocaleString("pt-BR", { minimumIntegerDigits: 2 }) +
    ".jpeg"
  );
}
