export enum ConfigOptions {
  SHOW_STATS = "configShowStats",
  PIXEL_RATIO = "configPixelRatio",
  CAMERA_ENABLE_PAN = "configCameraEnablePan",
}

const getBoolean = (option: ConfigOptions) => {
  const stored = localStorage.getItem(option) || "false";
  return stored === "true";
};

const setBoolean = (option: ConfigOptions, val: boolean) => {
  localStorage.setItem(option, val.toString());
};

const getNumber = (option: ConfigOptions, defaultValue: number = 0) => {
  const stored = localStorage.getItem(option) || defaultValue.toString();
  return parseFloat(stored);
};

const setNumber = (option: ConfigOptions, val: number) => {
  localStorage.setItem(option, val.toString());
};

const ConfigManager = {
  getBoolean,
  setBoolean,
  getNumber,
  setNumber,
};

export default ConfigManager;
