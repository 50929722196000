import { List } from "@mantine/core";
import LazyIcon from '../../LazyIcon';

export interface ApPanelListItem {
  name: string;
  icon: string;
}

export interface ApPanelListProps {
  itens: ApPanelListItem[];
}

export default function ApPanelList({ itens }: ApPanelListProps) {

  return (
    <List mt="sm" size="sm">
      {itens.map((item, index) => (
        <List.Item key={index} icon={<LazyIcon iconName={item.icon} />}>
          {item.name}
        </List.Item>
      ))}
    </List>
  );
}
