import { ReactNode } from "react";
import { Title } from "@mantine/core";

import styles from "./styles.module.css";
import ApPanelCard from "./panelsComponents/ApPanelCard";
import ApPanelCards from "./panelsComponents/ApPanelCards";
import ApPanelText from "./panelsComponents/ApPanelText";
import ApPanelList from "./panelsComponents/ApPanelList";

interface ApPanelProps {
  children: ReactNode;
  title?: string;
}

export default function ApPanel({ children, title }: ApPanelProps) {
  return (
    <div>
      {title && (
        <Title order={1} className={styles.title} mb="md">
          {title}
        </Title>
      )}

      {children}
    </div>
  );
}

ApPanel.Card = ApPanelCard;
ApPanel.Cards = ApPanelCards;
ApPanel.Text = ApPanelText;
ApPanel.List = ApPanelList;
