import { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AiOutlineCamera } from "react-icons/ai";

import { SimpleGrid, Button } from "@mantine/core";
import { useMediaQuery, useHotkeys } from "@mantine/hooks";

import EEventBus from "../../../../webgl/helpers/EEventBus";
import { EventBus } from "../../../../webgl/helpers/EventBus";

import { ApDrawerContext } from "../../../ApDrawer/ApDrawerContext";
import PanelOtherFloors from "../../../ApPanel/Panels/PanelOtherFloors";
import DroneViewFullscreen from "./DroneViewFullscreen";

import styles from "./styles.module.css";

//TODO: migrate consts to ProjectData json
export const MIN_FLOOR = 5;
export const MAX_FLOOR = 21;
export const MAX_PLAN = 4;
export const DEFAULT_PLAN = 0;
export const DEFAULT_FLOOR = 5;

const DefineInitialFloorValues = (apId?: string) => {
  let initialFloor = DEFAULT_FLOOR;
  let initialPlan = DEFAULT_PLAN;

  if (apId) {
    const floorParam = Math.floor(parseInt(apId) / 10);
    const planParam = parseInt(apId) % 10;
    let fixUrl = false;

    if (floorParam >= MIN_FLOOR && floorParam <= MAX_FLOOR)
      initialFloor = floorParam;
    else fixUrl = true;

    if (planParam >= 0 && planParam < MAX_PLAN) initialPlan = planParam;
    else fixUrl = true;

    // change url without reload or reender
    if (fixUrl)
      window.history.replaceState(
        null,
        "",
        `/ap/${initialFloor}${initialPlan}`
      );
  }

  return [initialFloor, initialPlan];
};

export default function FloorMiniMap() {
  const apDrawer = useContext(ApDrawerContext);
  const navigate = useNavigate();

  const { apId } = useParams();

  let [initialFloor, initialPlan] = useMemo(
    () => DefineInitialFloorValues(apId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [visible, setVisible] = useState(false);
  const [floor, setFloor] = useState(initialFloor);
  const [plan, setPlan] = useState(initialPlan);
  const [droneViewVisible, setDroneViewVisible] = useState(false);

  const isAlwaysVisible = useMediaQuery("only screen and (min-width: 768px)");
  useEffect(() => setVisible(visible || isAlwaysVisible), [isAlwaysVisible]);

  useHotkeys([
    ["ArrowRight", () => goRight()],
    ["ArrowLeft", () => goLeft()],
    ["ArrowUp", () => goUpstairs()],
    ["ArrowDown", () => goDownstairs()],
  ]);

  //apartment button render helper
  const ApButton = ({ ps }: { ps: number }) => (
    <Button
      fullWidth
      variant={plan === ps ? "filled" : "light"}
      style={{ width: 60, padding: 0 }}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        onChangePlan(ps);
      }}
    >
      {floor * 10 + ps}
    </Button>
  );

  const handleUpstairs = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    goUpstairs();
  };

  const handleDownstairs = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    goDownstairs();
  };

  const goUpstairs = () => {
    onChangeFloor(Math.min(floor + 1, MAX_FLOOR));
    if (floor === 5 && !droneViewVisible) apDrawer.close();
  };

  const goDownstairs = () => {
    onChangeFloor(Math.max(floor - 1, MIN_FLOOR));
    if (floor === MIN_FLOOR && !droneViewVisible)
      apDrawer.open(<PanelOtherFloors />);
  };

  const onChangeFloor = (value: number) => {
    EventBus.getInstance().dispatch(EEventBus.SET_APARTMENT_FLOOR, value);
    setFloor(value);

    const ap = value * 10 + plan;
    navigate(`/ap/${ap}`);
  };

  const handleLeftPlan = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    goLeft();
  };

  const handleRightPlan = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    goRight();
  };

  const goRight = () => onChangePlan((plan + 1) % 4);
  const goLeft = () => {
    let planNumber = plan - 1;
    onChangePlan(planNumber >= 0 ? planNumber : 3);
  };

  const onChangePlan = (value: number) => {
    EventBus.getInstance().dispatch(EEventBus.SET_APARTMENT_PLAN, value);
    setPlan(value);

    const ap = floor * 10 + value;
    navigate(`/ap/${ap}`);
  };

  const currentApNumber = (): number => floor * 10 + plan;

  return (
    <div
      className={`${styles.container} ${styles.left}`}
      onMouseEnter={() => setVisible(true || isAlwaysVisible)}
      onMouseLeave={() => setVisible(false || isAlwaysVisible)}
      onClick={() => setVisible(!visible || isAlwaysVisible)}
    >
      {visible ? (
        <div className={styles.visibleContent}>
          <>
            <span>Apartamentos</span>

            <SimpleGrid cols={2} spacing={8}>
              <ApButton ps={2} />
              <ApButton ps={0} />
              <ApButton ps={3} />
              <ApButton ps={1} />
            </SimpleGrid>

            <span>Andar: {floor}</span>
          </>

          <SimpleGrid cols={2} spacing={8}>
            <Button variant="light" onClick={handleDownstairs}>
              -
            </Button>
            <Button
              variant="light"
              onClick={handleUpstairs}
              disabled={floor === MAX_FLOOR}
            >
              +
            </Button>
          </SimpleGrid>

          {!isAlwaysVisible && (
            <Button fullWidth variant="light" onClick={() => setVisible(false)}>
              OK
            </Button>
          )}

          <Button
            fullWidth
            variant="light"
            leftIcon={<AiOutlineCamera size={22} />}
            onClick={() => setDroneViewVisible(true)}
          >
            Vista
          </Button>
        </div>
      ) : (
        <Button variant="subtle" className={styles.openContentButton}>
          {currentApNumber()}
        </Button>
      )}

      <DroneViewFullscreen
        visible={droneViewVisible}
        onClose={() => setDroneViewVisible(false)}
        floor={floor}
        plan={plan}
        controls={{
          handleUpstairs,
          handleDownstairs,
          handleRightPlan,
          handleLeftPlan,
          currentApNumber,
        }}
      />
    </div>
  );
}
