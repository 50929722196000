import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";

interface FrontContainerProps {
  children: React.ReactNode;
}

export default function FrontContainer({ children }: FrontContainerProps) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      console.log("res");
      setHeight(window.innerHeight);
    });
  }, []);

  return (
    <div className={styles.container} style={{ height }}>
      {children}
    </div>
  );
}
