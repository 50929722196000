import React, { Suspense } from 'react';
import { AiOutlineReload } from "react-icons/ai";

import styles from "./styles.module.css";

interface LazyIconProps {
  iconName: string
}

export default function LazyIcon({ iconName }: LazyIconProps) {

  const iconGroup = iconName.substring(0, 2).toLowerCase();
  const loadIconModule = `({ ${iconName} }) => ({ default: ${iconName} })`;
  const Icon = React.lazy(() => import(`react-icons/${iconGroup}/index.js`).then(eval(loadIconModule)));

  return (
    <Suspense fallback={<AiOutlineReload size={22} className={styles.loading} />}>
      <Icon size={22} />
    </Suspense>
  );
}
