import { useDocumentTitle } from "@mantine/hooks";
import { FiHelpCircle } from "react-icons/fi";
import ApDrawer from "../../components/ApDrawer";
import ApTopMenu, {
  ApTopMenuItems,
  ApTopMenuPosition,
  ApTopMenuShape,
} from "../../components/ApTopMenu";
import FrontContainer from "../../components/FrontContainer";
import JoyrideAp from "../../components/Joyrides/JoyrideAp";
import Vignette from "../../components/Vignette";
import WatermarkOOGLON from "../../components/WatermarkOOGLON";
import BuildingName from "../../components/BuildingName";
import WebGLWrapper from "../../components/WebGLWrapper";

const menuShape: ApTopMenuShape = [
  {
    block: 1,
    position: ApTopMenuPosition.LEFT,
    items: [{ id: ApTopMenuItems.MINI_MAP }],
  },
  {
    block: 2,
    position: ApTopMenuPosition.LEFT,
    items: [
      { id: ApTopMenuItems.INFO_BUTTON, args: { text: "Info Text" } },
      {
        id: ApTopMenuItems.GENERIC_BUTTON,
        args: {
          innerComponent: <FiHelpCircle size={20} color="dimgray" />,
          onClick: () => {
            localStorage.removeItem("joyrideApShown");
            window.location.reload();
          },
          id: "RepeatJoyrideButton",
        },
      },
    ],
  },
  {
    block: 3,
    position: ApTopMenuPosition.RIGHT,
    items: [{ id: ApTopMenuItems.HOME_BUTTON }],
  },
  {
    block: 4,
    position: ApTopMenuPosition.RIGHT,
    items: [
      { id: ApTopMenuItems.MAPARTMENT_DETAILS },
      { id: ApTopMenuItems.MSUN_CONTROLLER },
      { id: ApTopMenuItems.TOGGLE_FURNITURE },
      { id: ApTopMenuItems.TOGGLE_MEASURES },
      { id: ApTopMenuItems.BROKERS_CONTACT_BUTTON },
      { id: ApTopMenuItems.SHARE_BUTTON },
    ],
  },
  {
    block: 5,
    position: ApTopMenuPosition.RIGHT,
    items: [{ id: ApTopMenuItems.NORTH_INDICATOR }],
  },
];

export default function PageApartmentView() {
  useDocumentTitle("Apartamentos | Edifício São Luiz");

  return (
    <ApDrawer>
      <WebGLWrapper dvSceneName="DvEdificeInternal" />
      <Vignette />
      <WatermarkOOGLON />
      <BuildingName />
      <FrontContainer>
        <ApTopMenu menuShape={menuShape} />
      </FrontContainer>
      <JoyrideAp />
    </ApDrawer>
  );
}
