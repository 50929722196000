import { Card, CardProps, Title } from "@mantine/core";
import { ReactNode } from "react";

import styles from "../styles.module.css";

export interface ApPanelCardProps {
  children: ReactNode;
  title?: string;
}

export default function ApPanelCard({
  children,
  title,
  ...others
}: ApPanelCardProps & CardProps) {
  return (
    <Card p="md" radius="md" className={styles.container} {...others}>
      {title && (
        <Title order={1} className={styles.title}>
          {title}
        </Title>
      )}

      {children}
    </Card>
  );
}
