import { lazy, Suspense } from "react";

import { useToggleDisplayUI } from "../../hooks/useToggleUI";

import styles from "./styles.module.css";

export enum ApTopMenuItems {
  BACK_BUTTON = "BackButton",
  BROKERS_CONTACT_BUTTON = "BrokersContactButton",
  CAMERA_HEIGHT_CONTROLLER = "CameraHeightController",
  CONFIG_BUTTON = "ConfigButton",
  GENERIC_BUTTON = "GenericButton",
  HOME_BUTTON = "HomeButton",
  INFO_BUTTON = "InfoButton",
  MBUILDING_DETAILS = "MBuildingDetails",
  MAPARTMENT_DETAILS = "MApartmentDetails",
  MAIN_MENU = "MainMenu",
  MINI_MAP = "FloorMiniMap",
  MSUN_CONTROLLER = "MSunController",
  NORTH_INDICATOR = "NorthIndicator",
  PARTNERS_BUTTON = "PartnersButton",
  SHARE_BUTTON = "ShareButton",
  TOGGLE_FURNITURE = "ToggleFurniture",
  TOGGLE_MEASURES = "ToggleMeasures",
  TOGGLE_NEIGHBORHOOD = "ToggleNeighborhood",
}

export enum ApTopMenuPosition {
  LEFT,
  RIGHT,
}

export type ApTopMenuShape = Array<{
  block: number;
  position: ApTopMenuPosition;
  items: Array<{ id: ApTopMenuItems; args?: any }>; //TODO: worth type any?
}>;

interface ApTopMenuProps {
  menuShape: ApTopMenuShape;
}

const buildLazyMenu = (menuShape: ApTopMenuShape) =>
  menuShape.map(({ block, items }) => {
    return (
      <div className={`${styles.block} global-shadow`} key={block}>
        {items.map(({ id, args }, index) => {
          //lazy loading dynamic menu item
          const DynamicMenuItem = lazy(() => import(`./Items/${id}`));

          return (
            <div key={id + index}>
              {/* add hline for all items except first */}
              {index != 0 && <div className={styles.hline} />}

              <div className={styles.item} id={args?.id ? args.id : id}>
                <Suspense fallback={<div></div>}>
                  <DynamicMenuItem {...args} />
                </Suspense>
              </div>
            </div>
          );
        })}
      </div>
    );
  });

export default function ApTopMenu({ menuShape }: ApTopMenuProps) {
  const display = useToggleDisplayUI();

  const leftMenu = buildLazyMenu(
    menuShape.filter((ms) => ms.position == ApTopMenuPosition.LEFT)
  );

  const rightMenu = buildLazyMenu(
    menuShape.filter((ms) => ms.position == ApTopMenuPosition.RIGHT)
  );

  return (
    <div style={{ display }}>
      <div className={`${styles.container} ${styles.left}`}>{leftMenu}</div>
      <div className={`${styles.container} ${styles.right}`}>{rightMenu}</div>
    </div>
  );
}
