import { MathUtils } from "three";
import { degToRad, radToDeg } from "three/src/math/MathUtils";
import SunCalc from "../lib/suncalc3-2.0.5/suncalc";

export default class SunCalcHelper {
  // 2022 year for reference. The month is 0-indexed.
  // private dezSolsticeLast = new Date(2021, 11, 21, 12, 58);
  // private dezSolstice = new Date(2022, 11, 21, 18, 48);

  // 2023 year for reference. The month is 0-indexed.
  private dezSolsticeLast = new Date(2022, 11, 21, 18, 48);
  private dezSolstice = new Date(2023, 11, 22, 0, 27);

  private lat: number;
  private lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }

  /**
   * @param yearTarget range from 0 to 1 (last dez solstice to dez solstice)
   * @param hourTarget range from 0 to 1 (sunrise to sunshine)
   * @returns sun altitude, azimuth and declination in radians
   */
  public getSunData(yearTarget: number, hourTarget: number) {
    // interpolate year date from solstice to solstice
    const interpolatedYearDate = this.interpolateYearDate(yearTarget);

    // interpolate day hours from sunrise to sunset
    const interpolatedDayDate = this.interpolateDayDate(
      interpolatedYearDate,
      hourTarget
    );

    //get sun position for current settings
    const pos = SunCalc.getPosition(interpolatedDayDate, this.lat, this.lng);

    // get sun declination for current settings
    const declination = this.getSolarDeclination(interpolatedDayDate);

    //DEBUG in Degrees
    // console.log(
    //   "dc",
    //   declination,
    //   "az",
    //   radToDeg(pos.azimuth),
    //   "al",
    //   radToDeg(pos.altitude),
    //   interpolatedDayDate
    // );

    return {
      altitude: pos.altitude,
      azimuth: pos.azimuth,
      declination: degToRad(declination),
      zenith: pos.zenith,
    };
  }

  private getDayOfYear(d: Date) {
    var start = new Date(d.getFullYear(), 0, 0);
    var diff = d.valueOf() - start.valueOf();
    var oneDay = 1000 * 60 * 60 * 24;
    var dayOfYear = Math.floor(diff / oneDay);
    return dayOfYear;
  }

  /**
   * @returns year progress in percentage (0 to 1) considering solstices
   */
  public getProgressOfTheYear = () => {
    const now = new Date();

    const totalDuration =
      this.dezSolstice.getTime() - this.dezSolsticeLast.getTime();
    const currentDuration = now.getTime() - this.dezSolsticeLast.getTime();

    const progress = currentDuration / totalDuration;

    return progress;
  };

  /**
   * @returns hour progress in percentage (0 to 1) considering sunrise and sunset. A default value (0.6) is returned if current time is out of sunrise~sunset bounds.
   */
  public getProgressOfTheHour = () => {
    const now = new Date();
    const times = this.getTimesForDate(now);

    const totalDuration =
      times.sunset.value.getTime() - times.sunrise.value.getTime();
    const currentDuration = now.getTime() - times.sunrise.value.getTime();

    const progress = currentDuration / totalDuration;

    if (progress < 0 || progress > 1) return 0.6;

    return progress;
  };

  //https://www.sciencedirect.com/topics/engineering/solar-declination
  // The variation of the solar declination throughout the year is shown in Fig. 7. The declination δ, in degrees, for any day of the year (N) can be calculated approximately by the equation (ASHRAE, 2007):
  private getSolarDeclination(d: Date) {
    return (
      23.45 *
      Math.sin(((360 / 365.0) * (this.getDayOfYear(d) + 284) * Math.PI) / 180)
    );
  }

  public getTimesForDate = (date: Date) => {
    const times = SunCalc.getSunTimes(date, this.lat, this.lng);
    const sunrise = times.sunriseStart;
    const sunset = times.sunsetStart;

    return {
      sunrise,
      sunset,
    };
  };

  /**
   * @param yearTarget range from 0 to 1 (from last dez Solstice to current dez Solstice)
   * @returns interpolated Date
   */
  public interpolateYearDate(yearTarget: number) {
    return new Date(
      MathUtils.lerp(
        this.dezSolsticeLast.valueOf(),
        this.dezSolstice.valueOf(),
        yearTarget
      )
    );
  }

  public interpolateDayDate(interpolatedYearDate: Date, hourTarget: number) {
    const times = this.getTimesForDate(interpolatedYearDate);

    return new Date(
      MathUtils.lerp(
        times.sunrise.value.valueOf(),
        times.sunset.value.valueOf(),
        hourTarget
      )
    );
  }
}
