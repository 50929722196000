import { BrowserRouter, Route, Routes } from "react-router-dom";

import PageApartmentView from "./ApartmentView";
import PageHome from "./Home";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/ap" element={<PageApartmentView />} />
        <Route path="/ap/:apId" element={<PageApartmentView />} />
      </Routes>
    </BrowserRouter>
  );
}
