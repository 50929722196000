import { Mesh, Object3D } from "three";

export const filterChildMeshs = (parent: Object3D) => {
  const childs = new Array<Mesh>();

  if (parent.type === "Mesh") {
    childs.push(parent as Mesh);
  } else {
    parent.traverse((child) => {
      if (child.type === "Mesh") {
        childs.push(child as Mesh);
      }
    });
  }

  return childs;
};
